import React from 'react';

const ArrowDown = () => {
    return (
        <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 0.5L9 8.5L17 0.5" stroke="#053D81" strokeLinecap="round"/>
        </svg>
    );
};

export default ArrowDown;
