import * as React from 'react'
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

const BasicModal = ({ items }) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <a onClick={handleOpen}
               className="primary-button">
                {items.button}
            </a>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby={items.header}
                aria-describedby=""
            >
                <Fade in={open} timeout={300}>
                <div className="modalContainer">
                    <div className="modalWindow">
                        <header>
                            {items.header}
                            <span onClick={handleClose} className="close-button"></span>
                        </header>
                        <div dangerouslySetInnerHTML={{ __html: items.content }}></div>
                        <footer>
                            {items.footer}
                        </footer>
                    </div>
                </div>
                </Fade>
            </Modal>
        </>
    )
}
export default BasicModal;