import * as React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import "swiper/css/navigation"
import 'swiper/css/pagination'
// import { Navigation } from "swiper";
import SwiperCore, { Autoplay, Navigation, Pagination, Mousewheel } from 'swiper'

SwiperCore.use([Navigation, Pagination]);

const Slider = ({ items }) => {
    return (
        <Swiper className="mySwiper"
            //navigation
                direction={'horizontal'}
                pagination={ false }
                //mousewheel={{ releaseOnEdges: true }}

                speed={'2000'}
                autoplay={{
                    delay: 4500,
                    disableOnInteraction: false,
                }}
                modules={[Pagination, Autoplay]}
        >
            {items.map((item, index) => (
                <SwiperSlide key={index}>
                    <div className="slider-item">
                        {item.slide}
                        <img className="" src={item.image} alt="Stellar" />
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
}
export default Slider;