import * as React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDown from '../icons/arrowDown' // icon

const SimpleAccordion = ({ items }) => {

    return (
        <div>
            {items.map((item, index) => (
                <Accordion key={index}>
                    <AccordionSummary
                        expandIcon={<ArrowDown />}
                        aria-controls={`panel${index}a-content`}
                        id={`panel${index}a-header`}
                    >
                        <div>{item.title}</div>
                    </AccordionSummary>
                    <AccordionDetails className="custom-content">
                        <div>
                            <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
};

export default SimpleAccordion;